//

import $ from 'jquery';

import Alert from 'bootstrap/js/src/alert';
import Button from 'bootstrap/js/src/button';
import Carousel from 'bootstrap/js/src/carousel';
import Collapse from 'bootstrap/js/src/collapse';
import Dropdown from 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';
import Popover from 'bootstrap/js/src/popover';
import Scrollspy from 'bootstrap/js/src/scrollspy';
import Tab from 'bootstrap/js/src/tab';
import Toast from 'bootstrap/js/src/toast';
import Tooltip from 'bootstrap/js/src/tooltip';
import Util from 'bootstrap/js/src/util';

//

import './interactive-navbar';

export {
  Util,
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Popover,
  Scrollspy,
  Tab,
  Toast,
  Tooltip
};

($ => {
  if (typeof $ === 'undefined') {
    throw new TypeError(
      "SG Bootstrap's JavaScript requires jQuery. jQuery must be included before Bootstrap's JavaScript."
    );
  }
})($);
